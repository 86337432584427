import React, { useEffect, useState } from "react";
import { FilterDropdown, Navbar, CustomTable, CustomPagination } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { getVillageKPI } from "../redux/slice/kpiSlice";
import { getAllDistricts } from "../redux/slice/getUserSlice";
import { getFilteredBlockList, getFilteredVillageList } from "../redux/slice/marketSlice";
import CardGroup from "../activities/CardGroup";
import filterIcon from '../assets/filter.svg';
import { Link } from "react-router-dom";
import {columns} from '../activities/columns';
import { format } from "date-fns";

function VillageActivities() {
    const dispatch = useDispatch();
    const kpi = useSelector((state) => state?.kpiSlice);
    const districtList = useSelector((state) => state?.getUserSlice?.districtList);
    const blockList = useSelector((state) => state?.marketSlice?.blockList);
    const villageList = useSelector((state) => state?.marketSlice?.villageList);

    const [villageKPI, setVillageKPI] = useState();
    const [filters, setFilters] = useState({
        district: 'all',
        block: 'all',
        village: 'all',
        page: 1,
        crop_type: 'all'
    });
    const [lastUpdated, setLastUpdated]= useState();
    const [totalRecords, setTotalRecords] = useState(0);
    
    const cropList = [
        {name: 'Basmati', value: 'basmati'},
        {name: 'Non-Basmati', value: 'non_basmati'}
    ]
    const cardNames = {
        total_farmers_registered: 'Total Farmers Registered',
        total_land_recorded: 'Total Land Recorded',
        active_equipment_suppliers: 'Active Equipment Suppliers',
        farmers_with_confirmed_bookings: 'Farmers with Confirmed Bookings',
        farmers_with_completed_bookings: 'Farmers with Completed Bookings',
        confirmed_bookings_count: 'Confirmed Bookings (in acres)',
        completed_bookings_count: 'Completed Bookings (in acres)'
    }

    useEffect(() => {
        dispatch(getAllDistricts());
    }, []);

    useEffect(() => {
      
    }, [districtList, blockList, villageList]);

    useEffect(() => {
        if (filters.district !== 'all') dispatch(getFilteredBlockList(filters.district));
    }, [filters.district]);

    useEffect(() => {
        if (filters.block !== 'all') dispatch(getFilteredVillageList(filters.block));
    }, [filters.block]);

    useEffect(() => {
        setVillageKPI(kpi.data);
        setLastUpdated(kpi?.data?.last_updated || null);
        if (kpi && kpi.data && kpi.data.hasOwnProperty('total_records')) setTotalRecords(kpi.data.total_records);
    }, [kpi]);

    useEffect(() => {
        dispatch(getVillageKPI(filters));
    }, [filters]);

    function updateFilters(key, value) {
        const current = filters 
        let next = {[key]: value};
        // reset some filters if other filters are updated
        if (key !== 'page') next.page = 1; 
        if (key === 'district') next.block = 'all';
        if (key === 'district' || key === 'block') next.village = 'all';
        setFilters({...current, ...next});
    }
    
    function filteredData(data) {
        if (!data || !data.length) return [];
        if (data.length === 1 && Object.values(data[0]).filter(d => !!d).length === 0) return [];
        let next =  data.filter(d => {
            let show = true;
            if (filters.district !== 'all' && d.district_id && d.district_id !== filters.district) show = false;
            if (filters.block !== 'all' && d.block_id && d.block_id !== filters.block) show = false;
            if (filters.village !== 'all' && d.village_code && d.village_code !== filters.village) show = false;
            return show;
        });
        return next;
    }

    function resetFilters() {
        setFilters({
            district: 'all',
            block: 'all',
            village: 'all',
            page: 1,
            crop_type: 'all'
        })
    }

    function getDropdownData(name) {
        if (!name || !name.length) return [];
        if (name === 'district') {
            return districtList
                .filter(d => d.district_name && d.district_id)
                .map(d => ({name: d.district_name, value: d.district_id}));
        }
        if (name === 'block') {
            if (filters.district === 'all') return [];
            return blockList
                .filter(b => b.block_name && b.block_id)
                .map(b => ({name: b.block_name, value: b.block_id}));
        }
        if (name === 'village') {
            if (filters.block === 'all') return [];
            return villageList
                .filter(v => v.village_name && v.village_code)
                .map(v => ({name: v.village_name, value: v.village_code}));
        }
    }

    return(
        <>  
            <Navbar active="activities" />
            <div className="container mt-3">
                <div className="d-flex align-items-center">
                    <Link to="/activities" className="btn btn-light mb-2 me-2" title="Go Back">&#x1F878;</Link>
                    <h4>Village-wise Details</h4>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                            Last Updated: <strong> {lastUpdated && !isNaN(new Date(lastUpdated).getTime()) 
    ? format(new Date(lastUpdated), "MM/dd/yyyy") 
    : 'N/A'}</strong>
</div>
                    <div className="d-flex align-items-center">
                        <div>
                            <span>Filter By</span>
                            <img className="ms-1 me-2" src={filterIcon} alt="filter" style={{height: '1.25em'}}/>
                        </div>
                        <FilterDropdown callback={updateFilters} value={filters} type="district" isDisabled={kpi?.loading} data={getDropdownData('district')} sort />
                        <FilterDropdown callback={updateFilters} value={filters} type="block" isDisabled={kpi?.loading} defaultSelection="All Blocks" data={getDropdownData('block')} />
                        <FilterDropdown callback={updateFilters} value={filters} type="village" isDisabled={kpi?.loading} defaultSelection="All Villages" data={getDropdownData('village')} />
                        <FilterDropdown callback={updateFilters} value={filters} type="crop_type" isDisabled={kpi?.loading} defaultSelection="All Crop Types" data={cropList} autoWidth/>
                    </div>
                </div>
                <div id="cards">
                    <CardGroup cardNames={cardNames} data={villageKPI} />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-2">Active Users and Details</h5>
                    <div className="w-auto"><hr /></div>
                </div>
                <div>
                    <div className="custom-table-container">
                        <CustomTable id="village_table" data={filteredData(villageKPI?.village_data)} columns={columns} isLoading={kpi?.loading} reset={resetFilters}/>
                    </div>
                    <div className="d-flex justify-content-end">
                        <CustomPagination page={filters.page} total={totalRecords} numPerPage={100} callback={e => updateFilters('page', e)} isLoading={kpi?.loading}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default VillageActivities;