import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import DataTable from 'react-data-table-component';
import {format} from 'date-fns';
import { getMarketSource, getMarketCounts, getFilteredBlockList, getFilteredVillageList } from '../redux/slice/marketSlice';
import { Navbar, TabGroup, FilterDropdown } from '../components/';
import {market_columns} from '../market_data/columns';
import customStyles from '../components/DataTableCustomStyles'
import EmptyState from '../components/DataTableEmptyState';
import filterIcon from '../assets/filter.svg';

const Marketplace = () => {
    const dispatch = useDispatch();

    const market = useSelector((state) => state?.marketSlice);
    const isMarketLoading = useSelector((state) => state?.marketSlice?.loading);
    const counts = market.marketCounts;
    
    const [source, setSource] = useState('bale_demand');
    const [filters, updateFilters] = useState({
        district: 'all',
        block: 'all',
        village: 'all'
    });

    const serverBlockList = useSelector((state) => state?.marketSlice.blockList);
    const isBlockListLoading = useSelector((state) => state?.marketSlice?.loadingBlocks);

    const serverVillageList = useSelector((state) => state?.marketSlice.villageList);
    const isVillageListLoading = useSelector((state) => state?.marketSlice?.loadingVillages);
    
    const formatText = str => str?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || str;
    let districtList= [], villageList = [], blockList = [];
    market?.data?.forEach(row => {
        districtList.push({name: formatText(row.district_name), value: row.district_name, id: row.district_id});
        blockList.push({name: formatText(row.block_name), value: row.block_name, id: row.block_id});
        villageList.push({name: formatText(row.village_name), value: row.village_name, id: row.village_code});
    });

    useEffect(() => {
        dispatch(getMarketCounts());
        dispatch(getMarketSource(source));
    }, [dispatch]);

    function uniq(arr, key) {
        if (!arr || !arr.length || !key || !key.length) return [];
        return arr.filter(a => a[key]).reduce((unique, item) => {
            if (!unique.some(u => u[key] === item[key])) unique.push(item);
            return unique;
        }, []);
    }
  
   let lastUpdated = () => {
    let date = 'N/A';

    if (!market?.data || !Array.isArray(market.data)) {
        return date; // Return 'N/A' if market.data is null, undefined, or not an array
    }

    let latest = market.data
        .map(d => d?.modified_on)
        .filter(Boolean) // Filter out null or undefined values
        .reduce((a, b) => (a > b ? a : b), null);

    return latest ? format(new Date(latest), 'MM/dd/yyyy') : date;
};

    const sources = [
        {
            name: 'bale_demand',
            label: 'Bale Buyers',
            amount: counts?.bale_demand_count || 0
        },
        {
            name: 'bale_supply',
            label: 'Bale Suppliers',
            amount: counts?.bale_supply_count || 0
        },
        {
            name: 'equipment',
            label: 'Equipment Providers',
            amount: counts?.equipment_count || 0
        },
        {
            name: 'storage',
            label: 'Storage Units',
            amount: counts?.storage_count || 0
        }
    ];
    function changeSource(next) {
        dispatch(getMarketSource(next.name));
        setSource(next.name);
        resetFilters();
    }

    function resetFilters() {
        updateFilters({
            district: 'all',
            block: 'all',
            village: 'all'
        });
    }
    
    const isEqual = (a,b) => (a.toLowerCase() === b.toLowerCase());
    const exists = item => item && item !== null && item.length; 

    const filteredData = (data) => {
        if (!data || !data.length) return [];

        return data.filter(item => {
            let show = true;
            if (filters.district !== 'all' && (!exists(item.district_name) || !isEqual(item.district_name, filters.district))) show = false;
            if (filters.village !== 'all' && (!exists(item.village_name) || !isEqual(item.village_name, filters.village))) show = false;
            if (filters.block !== 'all' && (!exists(item.block_name) || !isEqual(item.block_name, filters.block))) show = false;
            return show;
        });
    }

    function filterChanged(name, value) {
        let next = {...filters, [name]: value};
        if (name === 'district' || name === 'block') next.village = 'all';
        if (name === 'district') next.block = 'all';
        updateFilters(next);
        if (name === 'district') {
            const id = districtList.find(d => d.value === value)?.id;
            dispatch(getFilteredBlockList(id));
        }
        if (name === 'block') {
            const id = whichBlockList().find(b => b.value === value)?.id;
            dispatch(getFilteredVillageList(id));
        }
    }

    function whichBlockList() {
        if (filters.district === 'all' || !serverBlockList || !serverBlockList.length) return uniq(blockList, 'id');
        return serverBlockList.map(b => ({name: b.block_name, value: b.block_name, id: b.block_id}));
    }

    function whichVillageList() {
        if (filters.block === 'all' || !serverVillageList || !serverVillageList.length) return uniq(villageList, 'name');
        return serverVillageList.map(v => ({name: v.village_name, value: v.village_name, id: v.village_id}));
    }

    return (
        <>
            <Navbar active="marketplace"/>
            <div className="container mt-5">
                <h3>Marketplace</h3>
                <TabGroup tabData={sources} callback={changeSource} active={source}/>
                <div className="d-flex justify-content-between align-items-center pt-4">
                    <div>
                        Last Updated: <strong>{lastUpdated()}</strong>
                    </div>
                    <div className="d-flex align-items-center">
                        <div>
                            <span>Filter By</span>
                            <img className="ms-1 me-2" src={filterIcon} alt="filter" style={{height: '1.25em'}}/>
                        </div>
                        <FilterDropdown callback={filterChanged} type="district" value={filters} defaultSelection="All Districts" data={uniq(districtList, 'id')} isLoading={isMarketLoading} />
                        <FilterDropdown callback={filterChanged} type="block" value={filters} defaultSelection="All Blocks" data={whichBlockList()} isLoading={isMarketLoading || isBlockListLoading} />
                        <FilterDropdown callback={filterChanged} type="village" value={filters} defaultSelection="All Villages" data={whichVillageList()} isLoading={isMarketLoading || isVillageListLoading} />
                        <button className="btn btn-outline-secondary ms-1 btn-reset" onClick={resetFilters}>Reset</button>
                    </div>
                </div>
            </div>
            <div className="container my-4">
                <DataTable 
                    columns={market_columns[source]} 
                    data={isMarketLoading ? [] : filteredData(market?.data)}
                    highlightOnHover="true"
                    pagination
                    customStyles={customStyles}
                    noDataComponent={<EmptyState isLoading={isMarketLoading} hasData={market?.length} reset={resetFilters}/>}
                    striped
                />
            </div>
        </>
        )
    }
    
    export default Marketplace
    