import React, { useEffect, useState } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  LabelList,
  Rectangle,
} from "recharts";
import moment from "moment";

const Chart = (props) => {
  const [selectedOption, setSelectedOption] = useState("Farmer Benefitted");
  const kpi = useSelector((state) => state?.kpiSlice?.data);
  const loader = useSelector((state) => state?.kpiSlice?.loading);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (kpi) {
      // Extract district names from the data
      const districtNames = kpi?.district_names;
      props?.onSelect(
        "Farmer Benefitted",
        moment(
          kpi?.last_updated?.farmer_beneffited,
          "YYYY-MM-DD HH:mm:ss"
        ).format("MM/DD/YYYY")
      );

      // Generate data for each district
      const data = districtNames?.map((districtName) => ({
        district: districtName,
        farmers_benifitted: kpi[districtName]?.farmers_benifitted,
        land_covered: kpi[districtName]?.land_covered,
        equipment_available: kpi[districtName]?.equipment_available,
      }));

      setFilteredData(data);
    }
  }, [kpi]);

  const handleSearch = (e) => {
    const selected = e.target.value;
    setSelectedOption(selected);

    let lastUpdated = "";

    if (selected === "Farmer Benefitted") {
      lastUpdated = moment(
        kpi?.last_updated?.farmer_beneffited,
        "YYYY-MM-DD HH:mm:ss"
      ).format("MM/DD/YYYY");
    } else if (selected === "Land Covered") {
      lastUpdated = moment(
        kpi?.last_updated?.land_covered,
        "YYYY-MM-DD HH:mm:ss"
      ).format("MM/DD/YYYY");
    } else if (selected === "Equipment Available") {
      lastUpdated = moment(
        kpi?.last_updated?.equipment_available,
        "YYYY-MM-DD HH:mm:ss"
      ).format("MM/DD/YYYY");
    }

    props?.onSelect(selected, lastUpdated);
  };

  const renderCustomBar = (props) => {
    const { payload } = props;
    return <Rectangle {...props} radius={[5, 5, 0, 0]} />;
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 d-flex justify-content-between">
          <span>
            <h6 className="crm_heading">Active Users and Details</h6>
          </span>
          <span className="hr_line"></span>
          <Link to="/activities/villages" className="village-link">
            Click here for village-wise details
          </Link>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-between">
                  <p className="h1_typography">
                    {selectedOption} <br />
                    {selectedOption === "Land Covered" && (
                      <span>Land Area in acres</span>
                    )}
                  </p>

                  <div className="form-group">
                    <select
                      className="form-select filter-dropdown mx-1 w-auto"
                      id="dropdown"
                      value={selectedOption}
                      onChange={handleSearch}
                    >
                      <option value="Farmer Benefitted">
                        Farmer Benefitted
                      </option>
                      <option value="Land Covered">Land Covered</option>
                      <option value="Equipment Available">
                        Equipment Available
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12 mt-4">
                  {loader ? ( // Display loader if data is being loaded
                    <div className="loader d-flex justify-content-center">
                      Loading...
                    </div>
                  ) : (
                    <div style={{ width: "100%", height: 300 }}>
                      <ResponsiveContainer>
                        <BarChart
                          data={filteredData}
                          margin={{ top: 20, right: 0, left: 20, bottom: 20 }}
                          barGap={6}
                        >
                          <XAxis dataKey="district" />
                          <YAxis domain={[0]} width={0} />
                          <Tooltip />
                          <Legend />

                          {selectedOption === "Farmer Benefitted" && (
                            <>
                              <Bar
                                dataKey="farmers_benifitted.farmers_registered"
                                fill="#1F6040"
                                name="Farmers Registered"
                                barSize={25}
                                minPointSize={10}
                                shape={renderCustomBar}
                              >
                                <LabelList
                                  dataKey="farmers_benifitted.farmers_registered"
                                  position="top"
                                />
                              </Bar>
                              <Bar
                                dataKey="farmers_benifitted.farmers_confirmed_bookings"
                                fill="#3AAE75"
                                name="Farmers with Confirmed Bookings"
                                barSize={25}
                                minPointSize={10}
                                shape={renderCustomBar}
                              >
                                <LabelList
                                  dataKey="farmers_benifitted.farmers_confirmed_bookings"
                                  position="top"
                                />
                              </Bar>
                              <Bar
                                dataKey="farmers_benifitted.farmers_completed_bookings"
                                fill="#4EF1A1"
                                name="Farmers with Completed Bookings"
                                barSize={25}
                                minPointSize={10}
                                shape={renderCustomBar}
                              >
                                <LabelList
                                  dataKey="farmers_benifitted.farmers_completed_bookings"
                                  position="top"
                                />
                              </Bar>
                            </>
                          )}
                          {selectedOption === "Land Covered" && (
                            <>
                              <Bar
                                dataKey="land_covered.land_registered"
                                fill="#1F6040"
                                name="Total Land Registered"
                                barSize={25}
                                minPointSize={10}
                                ry={10}
                                shape={renderCustomBar}
                              >
                                <LabelList
                                  dataKey="land_covered.land_registered"
                                  position="top"
                                  formatter={(value) => Math.ceil(value)}
                                />
                              </Bar>
                              <Bar
                                dataKey="land_covered.land_with_confirmed_bookings"
                                fill="#3AAE75"
                                name="Land with Confirmed Bookings"
                                barSize={25}
                                minPointSize={10}
                                shape={renderCustomBar}
                              >
                                <LabelList
                                  dataKey="land_covered.land_with_confirmed_bookings"
                                  position="top"
                                  formatter={(value) => Math.ceil(value)}
                                />
                              </Bar>
                              <Bar
                                dataKey="land_covered.land_with_completed_bookings"
                                fill="#4EF1A1"
                                name="Land with Completed Bookings"
                                barSize={25}
                                minPointSize={10}
                                shape={renderCustomBar}
                              >
                                <LabelList
                                  dataKey="land_covered.land_with_completed_bookings"
                                  position="top"
                                  formatter={(value) => Math.ceil(value)}
                                />
                              </Bar>
                            </>
                          )}
                          {selectedOption === "Equipment Available" && (
                            <>
                              <Bar
                                dataKey="equipment_available.super_seeder"
                                fill="#1F6040"
                                name="Super Seeder"
                                barSize={25}
                                minPointSize={10}
                                shape={renderCustomBar}
                              >
                                <LabelList
                                  dataKey="equipment_available.super_seeder"
                                  position="top"
                                  formatter={(value) => Math.ceil(value)}
                                />
                              </Bar>
                              <Bar
                                dataKey="equipment_available.Baler"
                                fill="#3AAE75"
                                name="Baler"
                                barSize={25}
                                minPointSize={10}
                                shape={renderCustomBar}
                              >
                                <LabelList
                                  dataKey="equipment_available.Baler"
                                  position="top"
                                  formatter={(value) => Math.ceil(value)}
                                />
                              </Bar>
                            </>
                          )}
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chart;
