import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/slice/authSlice";
import marketIcon from "../assets/market.svg";
import "./logIn.css";
import { resetState } from "../redux/slice/forgotPasswordSlice";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [showError, setShowError] = useState(false);

  let error = useSelector((state) => state.auth.error);
  let waiting = useSelector((state) => state.auth.isLoading);
  let isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
    setShowError(false);
  };

  const checkIfUserLoggedIn = async () => {
    const token = await localStorage.getItem("authToken");
    if (token !== "undefined" && token !== null && token !== "") navigate("/");
  };

  const handleNavigate = () => {
    navigate("/activities");
  };
  const handleNavigateForgotPsw = () => {
    navigate("/forgotPassword");
  };
  const formSubmit = (e) => {
    e.preventDefault();
    if (!credentials.username.length || !credentials.password.length) return;
    setShowError(true);
    dispatch(login(credentials));
  };
  useEffect(() => {
    checkIfUserLoggedIn();
  }, [checkIfUserLoggedIn]);

  return (
    <>
      <div className="d-md-flex flex-row-reverse align-items-center justify-content-between">
        <div className="col-md-6 col-12">
          <div className="container p-3 login-form-container">
            <h1 className="mb-0">CRM</h1>
            <h1 className="fw-normal">Haryana Portal</h1>
            <hr className="my-4" />
            <h3>Login</h3>
            <form onSubmit={formSubmit}>
              <div className="input-wrapper mt-3">
                <label className="mb-2" htmlFor="username">
                  Username <span>*</span>
                </label>
                <input
                  name="username"
                  type="text"
                  className="form-control"
                  onChange={handleInputChange}
                  placeholder="Enter Username"
                  required
                />
              </div>
              <div className="input-wrapper mt-3">
                <label className="mb-2" htmlFor="pw">
                  Password <span>*</span>
                </label>
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  onChange={handleInputChange}
                  placeholder="Enter Password"
                  required
                />
              </div>
              <div className="error text-center text-danger mt-3">
                {showError && error
                  ? Object.values(error).length === 1
                    ? Object.values(error)[0]
                    : "An Unkown Error Occurred"
                  : ""}
              </div>
              <div className="text-end py-3">
                <button
                  className={`btn btn-success w-100 ${
                    waiting || isAuthenticated ? "disabled" : ""
                  }`}
                >
                  {isAuthenticated
                    ? "Logged In. Redirecting..."
                    : waiting
                    ? "Logging in..."
                    : "Login"}
                </button>
              </div>
            </form>
            <div className="row">
              <div className="col-md-12 col-sm-12 d-flex justify-content-end forgotText">
                <p onClick={handleNavigateForgotPsw}>Forgot Password?</p>
              </div>
            </div>
            <div className="btn_access">
              <button onClick={handleNavigate}>
                <span className="btn-1">Click to access</span>
                <br />
                <span className="btn-2">
                  Marketplace, Events and CRM Activities{" "}
                </span>
              </button>
            </div>
            <div className="gps-impl">
              <span className="gps-text">For GPS Implementation,</span>{" "}
              <a href={"https://crmharyana.nexsss.com/"}>
                <span className="gps-link">Click Here </span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 login-img">&nbsp;</div>
      </div>
    </>
  );
}
export default Login;
